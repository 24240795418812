.BoardContainer {
  position: relative;
  text-align: center;
  align-items: center;

  margin-bottom: 1em;
}

.BoardContainer > div {
  position: absolute;
  top: 0;
  left: 0;
}

.Board {
  text-align: center;
  display: grid;
}
