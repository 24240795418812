.Cell {
  height: 50px;
  width: 50px;
  border: 2px #b0b0b0 solid;
  background-color: #ffffff;
  color: #000000;
  margin: -1px;  /* Makes everything snug! */

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  touch-action: none;
}

.isSelected {
  background-color: yellow !important;
}

.isGreyedOut {
  background-color: #c7e8ff;
}

.borderLeft {
  border-left: 2px #000000 solid !important;
}

.borderRight {
  border-right: 2px #000000 solid !important;
}

.borderTop {
  border-top: 2px #000000 solid !important;
}

.borderBottom {
  border-bottom: 2px #000000 solid !important;
}
